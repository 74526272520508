import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const EnvWarning = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        width: "100%",
        backgroundColor: "error.main",
        zIndex: 1000
      }}
    >
      <Typography variant="subtitle1" color="common.white">
        Detta är ingen produktionsmiljö
      </Typography>
    </Box>
  );
};

export default EnvWarning;
